export default [
    {
      id: 1,
      title: "Title",
      artist: "Gruppe",
      author: "???",
      arr: "???"
    },

    {
      id: 2,
      title: "Title",
      artist: "Gruppe",
      author: "???",
      arr: "???"
    },

    {
      id: 3,
      title: "Title",
      artist: "Gruppe",
      author: "???",
      arr: "???"
    },

    {
      title: "Pause",
    },

    {
      id: 4,
      title: "Title",
      artist: "Gruppe",
      author: "???",
      arr: "???"
    },


    {
      id: 5,
      title: "Title",
      artist: "Gruppe",
      author: "???",
      arr: "???"
    },

    {
      id: 6,
      title: "Title",
      artist: "Gruppe",
      author: "???",
      arr: "???"
    },
  ]